.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  font-family: 'Hind', sans-serif !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --primary-color: rgb(234, 202, 50) !important;
  /* --secondary-color: rgb(233, 245, 219) !important; */
}

.icon-header {
  width: 40px;
  height: auto;
}

.logo {
  width: 300px;
  height: auto;
}

@media (max-width: 991px) {
  .logo {
    width: 200px;
  }
}

.navigation {
  background-color: var(--primary-color);
  font-size: 24px;
  font-weight: 700;
}

.navigation-font {
  font-weight: 700 !important;
  font-size: 18px !important;
}

.slider-image {
  position: relative;
  width: 100%;
  height: 800px;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-text {
  color: var(--primary-color); 
  font-size: 56px; 
  text-align: center;
  font-weight: 700;
}

@media (max-width: 991px) {
  .slider-text {
    font-size: 40px;
  }
}

.slider-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.slider-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;color: white;
}

.form-input-fields {
  margin-bottom: 40px;
  border: none;
  border-bottom: 1px solid var(--primary-color);
  width: -webkit-fill-available;
}

.btn-form-submit {
  border: none;
  background-color: var(--primary-color);
  color: white;
  padding: 15px 80px;
  font-size: 26px;
  font-weight: 700;
}

.form-container {
  border-radius: 30px;
  border-top: 10px dashed black;
  padding: 50px 100px !important;
  transform: translateY(-150px) !important;
  background-color: white;
  margin-bottom: -90px;
}

@media (max-width: 991px) {
  .form-container {
    border-radius: 0px;
    padding: 30px 15px !important;
    transform: translateY(0px) !important;
  }
}

.service {
  background-image: url(/src/images/background/service-bg.webp);
}

/* .service-inner {
  background-image: url(/src/images/background/service-bg-line.png);
  background-size: cover;
  background-position: center;
  overflow: hidden;
} */

.service-inner {
  background-image: url(/src/images/background/service-bg-line.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  min-height: 500px;
}

@media (max-width: 767px) {
  .service-inner {
    min-height: 300px;
  }
}

.service-text {
  font-size: 50px;
  font-weight: 700;
}

.link-style-button {
  color: white;
  background-color: black;
  padding: 10px 70px;
  border-radius: 10px;
  font-size: 25px;
  font-weight: 700;
}

.image-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}

.image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.single-service-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.counter-bg {
  background-color: var(--primary-color);
}

.counter {
  font-size: 70px;
  font-weight: 700;
}

.icon {
  font-size: 55px;
}

.rotate-icon {
  transform: rotate(310deg);
}

.navbar-icon-size {
  font-size: 24px;
}

.yellow-bg {
  background-color: var(--primary-color);
}

.border-right {
  border-right: 2px solid #000;
}

.font-yellow {
  color: var(--primary-color);
}

.bg-white {
  background-color: white;
}

.bg-black {
  background-color: black;
}

.bg-grey {
  background-color: rgb(246, 246, 246);
}

.customer-container {
  max-width: 600px;
}

.icon-footer {
  color: var(--primary-color);
  font-size: 30px;
}

.bb-yellow {
  border-bottom: 2px solid var(--primary-color);
}

.detail-price li {
  margin-top: 30px;
}

.btn-menu-mobile {
  border: none !important;
}

@media (max-width: 991px) {
  .hide-on-small {
    display: none !important;
  }
}

.height-price-header {
  min-height: 135px;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
}

.back-to-top-button {
  background: none;
  border: none;
}

.btn-menu-mobile {
  border: none !important;
  background-color: transparent;
}